import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    deleteCorporateUserApi,
    getAllDaidoEmployeesPerBranches,
    getCorporateUserDetails,
    getEmployeesByAdmin,
    getRegisteredCorporateUsersByDaidoEmployee,
    removeCorporateUserLockApi,
    resaveAndResendRegistrationEmail,
    resendRegistrationEmail,
    sendRegistrationMail,
    totalDeleteCorporateUserApi,
    transfer,
    updateCorporateDetails,
} from 'api/corporate';

import { FetchEmployeeRequest } from 'types';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';

export type RequestRegistrationMailRequest = {
    'user[email]': string;
    'user[role_id]': number;
    'company[name]': string;
    'profile[lastname]': string;
    'profile[firstname]': string;
    'branch[code]': string;
    'department[code]': string;
    'employee[code]': string;
};

export type RequestResaveAndResendRequest = {
    'user[email]': string;
    'company[name]': string;
    'profile[lastname]': string;
    'profile[firstname]': string;
};

export const DELETE_CORPORATE_USERS = 'DELETE_CORPORATE_USERS';
export const RESEND_CORPORATE_USERS = 'RESEND_CORPORATE_USERS';
export const EDIT_CORPORATE_PROFILE = 'EDIT_CORPORATE_PROFILE';
export const FETCH_CORPORATE_USER_DETAILS = 'FETCH_CORPORATE_USER_DETAILS';
export const REMOVE_CORPORATE_USER_LOCK = 'REMOVE_CORPORATE_USER_LOCK';
export const RESAVE_RESEND_REGISTRATION_MAIL = 'RESAVE_RESEND_REGISTRATION_MAIL';
export const HARD_DELETE_CORPORATE_USERS = 'HARD_DELETE_CORPORATE_USERS';

export type TransformedTransferCorporateRequest = {
    uuid: string;
    branch_code: number;
    employee_code: number;
};

export type TransferCorporateRequest = {
    uuid: string;
    branchCode: number;
    employeeCode: number;
};

type InsurancePlans = {
    code: string;
    name: string;
};

export type CorporatePagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type Corporate = {
    uuid: string;
    email: string;
    profile: CorporateProfile;
    company: Company;
    status: number;
    insurance_plans: InsurancePlans[];
    locked: boolean;
};

type CorporateProfile = {
    lastname: string;
    firstname: string;
    lastname_furigana: string;
    firstname_furigana: string;
    phone: string;
};

type Company = {
    name: string;
    name_furigana: string;
    postal_code: string;
    address: string;
    building_name: string;
    representative_position: string;
    parttime_employee_count: number;
    labor_regulations_compliant: number;
    allow_sharoushi: number;
    allow_shindanshi: number;
    prefecture_code: string;
    industry_code: string;
    employee_count_range_code: string;
    daido_branch_name: string;
    daido_department_name: string;
    daido_employee_name: string;
};

export type CorporateData = {
    employees: Employee[];
    corporateUsers: CorporateUsers[];
    employeesPerBranch: EmployeePerBranch[];
    corporate: Corporate;
    isRegistring: boolean;
    isRegistringSuccess: boolean;
    isRegistringFailure: boolean;
    isRequesting: boolean;
    isRequestSuccess: boolean;
    isRequestError: boolean;
    messageFromRequest: string;
    requestType: string;
    pagination: CorporatePagination;
};

export type CorporateUsers = {
    key: number;
    corporateUuid: string;
    corporateName: string;
    corporateEmail: string;
    industry: string;
    employeeCountRange: string;
    interviewCount: number;
};

export type EmployeePerBranch = {
    daidoBranchCode: string;
    daidoBranchName: string;
    employees: Employee[];
};

export interface Employee {
    key?: number;
    departmentCode: string;
    departmentName: string;
    employeeCode: string;
    employeeName: string;
    email: string;
    numberOfCorpUsersManaged: number;
    daidoBranchCode?: string;
    daidoBranchName?: string;
}

type FetchCorporateUsersPayload = {
    employeeCode: string;
};

type EmployeeData = {
    department_code: string;
    department_name: string;
    employee_code: string;
    employee_name: string;
    email: string;
    number_of_corps_users_managed: number;
    daido_branch_code?: string;
    daido_branch_name?: string;
};

type CorporateUsersData = {
    corporate_uuid: string;
    corporate_name: string;
    corporate_email: string;
    industry: string;
    employee_count_range: string;
    interview_count: number;
};

type BranchData = {
    code: string;
    name: string;
    daido_departments: DaidoDepartment[];
};
type DaidoDepartment = {
    code: string;
    name: string;
    daido_employees: DaidoEmployee[];
};

type DaidoEmployee = {
    code: string;
    name: string;
    number_of_corps_users_managed: number;
    user: {
        email: string;
        profile: {
            employee_name: string;
        };
    };
};

export type CorporateFormData = {
    'company[name]': string;
    'company[name_furigana]': string;
    'company[representative_position]': string;
    'profile[lastname]': string;
    'profile[firstname]': string;
    'profile[lastname_furigana]': string;
    'profile[firstname_furigana]': string;
    'profile[phone]': string;
    'user[email]'?: string;
    'company[postal_code]': string;
    'company[prefecture_code]': string;
    'company[address]': string;
    'company[building_name]': string;
    'company[industry_code]': string;
    'company[parttime_employee_count]': string;
    'company[employee_count_range_code]': string;
    'insurance_plans[]': string[];
    'company[labor_regulations_compliant]': boolean;
    'company[allow_sharoushi]': boolean;
    'company[allow_shindanshi]': boolean;
    'user[password]'?: string;
    'user[password_confirmation]'?: string;
};

type UpdateCorporateDetailsRequest = {
    values: CorporateFormData;
    uuid: string;
};

type ResaveAndResendDetailsRequest = {
    data: RequestResaveAndResendRequest;
    uuid: string;
};

export const fetchListOfEmployees = createAsyncThunk('daido/employees', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getEmployeesByAdmin();
        const { data = {}, success } = response.data;
        if (success && data?.employees?.length) {
            dispatch(setCorporateData(data));
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchRegisteredCorporateUsersByDaidoEmployee = createAsyncThunk(
    'daido/employee/corporate/users',
    async ({ employeeCode }: FetchCorporateUsersPayload, { dispatch, rejectWithValue }) => {
        try {
            let response = await getRegisteredCorporateUsersByDaidoEmployee(employeeCode);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setCorporateUsers(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchAllDaidoEmployeesPerBranches = createAsyncThunk(
    'daido/branches/employees',
    async (params: FetchEmployeeRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getAllDaidoEmployeesPerBranches(params);
            const { success } = response.data;
            if (success) {
                dispatch(setEmployeePerBranch(response.data));
                dispatch(setPagination(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const requestRegistrationMail = createAsyncThunk(
    'daido/corporate/registration',
    async (request: RequestRegistrationMailRequest, { rejectWithValue }) => {
        try {
            request['user[role_id]'] = 4;
            let response = await sendRegistrationMail(request);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchCorporateUserDetails = createAsyncThunk(
    'daido/corporate/details',
    async (uuid: string, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCorporateUserDetails(uuid);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setCorporateDetails(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const transferCorporate = createAsyncThunk(
    'daido/corporate/transfer',
    async (request: TransferCorporateRequest, { rejectWithValue }) => {
        const transformedRequest: TransformedTransferCorporateRequest = {
            uuid: request.uuid,
            branch_code: request.branchCode,
            employee_code: request.employeeCode
        };
        try {
            let response = await transfer(transformedRequest);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Resend user's registration data
 **/
export const resendCorporateRegistration = createAsyncThunk(
    'daido/corporate/resendRegistration',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await resendRegistrationEmail(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Re-save user data and resend user's registration data
 **/
export const resaveAndResendRegistrationMail = createAsyncThunk(
    'daido/employee/corporate/resaveAndResend',
    async (request: ResaveAndResendDetailsRequest, { rejectWithValue }) => {
        const { uuid, data } = request;
        try {
            let response = await resaveAndResendRegistrationEmail(uuid, data);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * PATCH user's registration data
 **/
export const updateCorporateProfile = createAsyncThunk(
    'daido/corporate/updateProfile',
    async (request: UpdateCorporateDetailsRequest, { rejectWithValue }) => {
        const { values, uuid } = request;
        try {
            let response = await updateCorporateDetails(values, uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * DELETE user's registration data
 **/
export const deleteCorporateUsers = createAsyncThunk(
    'daido/corporate/deleteCorporateUser',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await deleteCorporateUserApi(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * DELETE user's registration data
 **/
export const hardDeleteCorporateUsers = createAsyncThunk(
    'daido/corporate/totalDeleteCorporateUser',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await totalDeleteCorporateUserApi(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Remove Login Restriction of a Corporate User
 **/
export const removeCorporateUserLock = createAsyncThunk(
    'daido/corporate/removeCorporateUserLock',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await removeCorporateUserLockApi(uuid);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const corporateSlice = createSlice({
    name: 'corporate',
    initialState: {
        employees: [] as Employee[],
        corporateUsers: [] as CorporateUsers[],
        employeesPerBranch: [] as EmployeePerBranch[],
        corporate: {} as Corporate,
        isRequestError: false,
        isRequestSuccess: false,
        isRequesting: false,
        messageFromRequest: '',
        requestType: '',
        pagination: {} as CorporatePagination
    } as CorporateData,
    reducers: {
        setCorporateData: (state, action) => {
            const { employees } = action.payload;
            const employeeList = [] as Employee[];
            employees.forEach((employee: EmployeeData, index: number) => {
                employeeList.push({
                    key: index + 1,
                    departmentCode: employee.department_code,
                    departmentName: employee.department_name,
                    employeeCode: employee.employee_code,
                    employeeName: employee.employee_name,
                    email: employee.email,
                    numberOfCorpUsersManaged: employee.number_of_corps_users_managed,
                    daidoBranchCode: employee.daido_branch_code,
                    daidoBranchName: employee.daido_branch_name
                });
            });
            state.employees = employeeList;
        },
        setCorporateUsers: (state, action) => {
            const { corporates } = action.payload;
            const corporateUsersList = [] as CorporateUsers[];
            corporates.forEach((corporate: CorporateUsersData, index: number) => {
                corporateUsersList.push({
                    key: index + 1,
                    corporateUuid: corporate.corporate_uuid,
                    corporateName: corporate.corporate_name,
                    corporateEmail: corporate.corporate_email,
                    industry: corporate.industry,
                    employeeCountRange: corporate.employee_count_range,
                    interviewCount: corporate.interview_count
                });
            });
            state.corporateUsers = corporateUsersList;
        },
        setEmployeePerBranch: (state, action) => {
            const { data } = action.payload;
            state.employeesPerBranch = data.map((branch: BranchData) => {
                const employeeList = [] as Employee[];

                for (let department of branch?.daido_departments ?? []) {
                    for (let employee of department?.daido_employees ?? []) {
                        employeeList.push({
                            departmentCode: department.code,
                            departmentName: department.name,
                            employeeCode: employee.code,
                            employeeName: employee?.user?.profile?.employee_name,
                            email: employee.user.email,
                            numberOfCorpUsersManaged: employee.number_of_corps_users_managed
                        });
                    }
                }

                return {
                    daidoBranchCode: branch.code,
                    daidoBranchName: branch.name,
                    employees: employeeList
                };
            });
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page
            };
        },
        setCorporateDetails: (state, { payload }) => {
            const { user, company, profile, insurance_plans } = payload;
            state.corporate.uuid = user.uuid;
            state.corporate.email = user.email;
            state.corporate.status = user.status;
            state.corporate.company = company;
            state.corporate.profile = profile;
            state.corporate.insurance_plans = insurance_plans;
            state.corporate.locked = user.login_restriction !== null;
        },
        resetRequestState: (state) => {
            state.isRequestError = false;
            state.isRequestSuccess = false;
            state.isRequesting = false;
            state.messageFromRequest = '';
            state.requestType = '';
        },
        resetCorporateDetails: (state) => {
            state.corporate.uuid = '';
            state.corporate.email = '';
            state.corporate.company = {} as Company;
            state.corporate.profile = {} as CorporateProfile;
            state.corporate.insurance_plans = [] as InsurancePlans[];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchListOfEmployees.pending, (state: CorporateData) => {
            state.isRequesting = true;
        });
        builder.addCase(fetchListOfEmployees.rejected, (state: CorporateData, action) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.messageFromRequest = action.payload as string;
        });
        builder.addCase(fetchListOfEmployees.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
        });
        builder.addCase(fetchAllDaidoEmployeesPerBranches.pending, (state: CorporateData) => {
            state.isRequesting = true;
        });
        builder.addCase(fetchAllDaidoEmployeesPerBranches.rejected, (state: CorporateData) => {
            state.isRequesting = false;
        });
        builder.addCase(fetchAllDaidoEmployeesPerBranches.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
        });
        builder.addCase(requestRegistrationMail.pending, (state: CorporateData) => {
            state.isRegistring = true;
        });
        builder.addCase(requestRegistrationMail.rejected, (state: CorporateData, action) => {
            state.isRegistring = false;
            state.isRegistringSuccess = false;
            state.isRegistringFailure = true;
            state.messageFromRequest = action.payload as string;
        });
        builder.addCase(requestRegistrationMail.fulfilled, (state: CorporateData) => {
            state.isRegistring = false;
            state.isRegistringSuccess = true;
        });
        builder.addCase(updateCorporateProfile.pending, (state: CorporateData) => {
            state.isRequesting = true;
            state.requestType = EDIT_CORPORATE_PROFILE;
        });
        builder.addCase(updateCorporateProfile.rejected, (state: CorporateData, action) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.messageFromRequest = action.payload as string;
            state.requestType = EDIT_CORPORATE_PROFILE;
        });
        builder.addCase(updateCorporateProfile.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
            state.requestType = EDIT_CORPORATE_PROFILE;
        });
        builder.addCase(deleteCorporateUsers.pending, (state: CorporateData) => {
            state.isRequesting = true;
            state.requestType = DELETE_CORPORATE_USERS;
        });
        builder.addCase(deleteCorporateUsers.rejected, (state: CorporateData, action) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.messageFromRequest = action.payload as string;
            state.requestType = DELETE_CORPORATE_USERS;
        });
        builder.addCase(deleteCorporateUsers.fulfilled, (state: CorporateData) => {
            state.isRequestSuccess = true;
            state.requestType = DELETE_CORPORATE_USERS;
        });
        builder.addCase(resendCorporateRegistration.pending, (state: CorporateData) => {
            state.isRequestSuccess = false;
            state.requestType = RESEND_CORPORATE_USERS;
        });
        builder.addCase(resendCorporateRegistration.rejected, (state: CorporateData) => {
            state.isRequestSuccess = false;
            state.requestType = RESEND_CORPORATE_USERS;
        });
        builder.addCase(resendCorporateRegistration.fulfilled, (state: CorporateData) => {
            state.isRequestSuccess = true;
            state.requestType = RESEND_CORPORATE_USERS;
        });
        builder.addCase(fetchCorporateUserDetails.pending, (state: CorporateData) => {
            state.isRequesting = true;
            state.requestType = FETCH_CORPORATE_USER_DETAILS;
        });
        builder.addCase(fetchCorporateUserDetails.rejected, (state: CorporateData) => {
            state.isRequesting = false;
            state.requestType = FETCH_CORPORATE_USER_DETAILS;
        });
        builder.addCase(fetchCorporateUserDetails.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
            state.requestType = FETCH_CORPORATE_USER_DETAILS;
        });
        builder.addCase(removeCorporateUserLock.pending, (state: CorporateData) => {
            state.isRequesting = true;
            state.requestType = REMOVE_CORPORATE_USER_LOCK;
        });
        builder.addCase(removeCorporateUserLock.rejected, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.requestType = REMOVE_CORPORATE_USER_LOCK;
        });
        builder.addCase(removeCorporateUserLock.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
            state.isRequestError = false;
            state.requestType = REMOVE_CORPORATE_USER_LOCK;
        });
        builder.addCase(resaveAndResendRegistrationMail.pending, (state: CorporateData) => {
            state.isRequesting = true;
            state.requestType = RESAVE_RESEND_REGISTRATION_MAIL;
        });
        builder.addCase(resaveAndResendRegistrationMail.rejected, (state: CorporateData, action) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.messageFromRequest = action.payload as string;
            state.requestType = RESAVE_RESEND_REGISTRATION_MAIL;
        });
        builder.addCase(resaveAndResendRegistrationMail.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
            state.isRequestError = false;
            state.messageFromRequest = '';
            state.requestType = RESAVE_RESEND_REGISTRATION_MAIL;
        });
        builder.addCase(hardDeleteCorporateUsers.pending, (state: CorporateData) => {
            state.isRequesting = true;
            state.requestType = HARD_DELETE_CORPORATE_USERS;
        });
        builder.addCase(hardDeleteCorporateUsers.rejected, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.requestType = HARD_DELETE_CORPORATE_USERS;
        });
        builder.addCase(hardDeleteCorporateUsers.fulfilled, (state: CorporateData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
            state.isRequestError = false;
            state.requestType = HARD_DELETE_CORPORATE_USERS;
        });
    }
});

export const {
    setCorporateData,
    resetRequestState,
    setCorporateUsers,
    setEmployeePerBranch,
    setCorporateDetails,
    resetCorporateDetails,
    setPagination
} = corporateSlice.actions;
export const corporateSelector = (state: RootState) => state.corporate;
